var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('view-detail',{attrs:{"index":"order","title":"Detalhes da Ordem de Serviço","apiUrl":"service-desk/order","apiParams":"?with=events;finances;status:id,name,icon,color;category:id,name,icon,color,form_id;category.form;items;items.item:id,name,type,item_unit_id;items.item.unit:id,code;business:id,type,form_reply_id,created_at,business_category_id;business.category.form;businesses:id,type,business_id,created_at","store":"order"},scopedSlots:_vm._u([{key:"toolbarItensBefore",fn:function(ref){
var content = ref.content;
var refresh = ref.refresh;
var loading = ref.loading;
return [(content)?_c('BusinessActions',{attrs:{"business":content,"refresh":refresh,"loading":loading}}):_vm._e()]}},{key:"default",fn:function(ref){
var content = ref.content;
var refresh = ref.refresh;
var loading = ref.loading;
return [_c('v-container',{staticStyle:{"overflow":"auto"},style:(_vm.$route.meta.scroll ? { 'height': _vm.height } : {}),attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('BusinessRelationships',{attrs:{"business":content,"refresh":refresh,"loading":loading}})],1),_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('BusinessInfo',{attrs:{"info":_vm.configuration,"data":content}})],1),(content.person && content.person.id)?_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('BusinessAddress',{attrs:{"title":"Endereço de Execução","business":content,"person":content.person || {},"address":content.address || {}},on:{"onSubmit":refresh}})],1):_vm._e(),(content.category.form && content.category.form.id)?_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('BusinessForm',{attrs:{"refresh":refresh,"reply":content.reply,"businessId":content.id,"form":content.category.form,"replyEditor":content.reply_editor}})],1):_vm._e(),(content.observation)?_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('BusinessObservation',{attrs:{"observation":content.observation}})],1):_vm._e(),(!_vm.$WiCheckPermission(_vm.PERMISSION_INTERNET_MODULE))?_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('BusinessItems',{attrs:{"business":content,"wiConfigId":"sale","onRefresh":refresh,"showAmounts":false}})],1):_vm._e(),(
            content.business &&
            content.business.category &&
            content.business.category.form &&
            content.business.category.form.id
          )?_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('BusinessForm',{attrs:{"refresh":refresh,"reply":content.business.reply,"businessId":content.business.id,"businessBusinessId":content.id,"form":content.business.category.form,"replyEditor":content.business.reply_editor}})],1):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }