<template>
  <v-app id="inspire" :dark="$wiDark">
    <DrawerLeft/>
    <AppBar/>
    <DrawerRight/>
    <DrawerTalks/>
    <v-content>
      <v-container fluid style="padding:0 !important;">
        <router-view></router-view>
      </v-container>
    </v-content>
    <Components/>
  </v-app>
</template>
<script>
  import { mapActions } from 'vuex'
  import DrawerLeft from '@/default/pages/MainNew/components/DrawerLeft/DrawerLeft'
  import AppBar from '@/default/pages/MainNew/components/AppBar/AppBar'
  import DrawerRight from '@/default/pages/MainNew/components/DrawerRight/DrawerRight'
  import DrawerTalks from '@/default/pages/MainNew/components/DrawerTalks/DrawerTalks'
  import Components from '@/default/pages/Main/Components'
  export default {
    name: 'Main',
    methods: {
      ...mapActions('Pages/Main', ['getDarkFromStorage']),
      ...mapActions('Talks', ['getTalks', 'subscribeOnTalks']),
      setStorageUserInStoreUser: function () {
        this.$store.dispatch('User/setUser', this.$Auth.user())
      },
      subscriptionNotifications: function () {
        if (window.Echo) {
          window.Echo.private(this.$Auth.user().account.id + '.person.' + this.$Auth.user().uuid)
          .notification(notification => {
            this.$store.commit('Pages/Main/Notifications/ADD_NOTIFICATION', notification)
            this.$Notifications.desktopNotification(notification)
          })
          .listen('.auth.changed', (user) => {
            this.updateAuthUserInStorageAndStore(this.$Storage.crypt(user))
          })
        }
      },
      getAuthUserDataUpdated: function () {
        this.$Api.get({uri: 'auth/check', params: {}, sucess: (response) => {
          this.updateAuthUserInStorageAndStore(response.data.token)
        }})
      },
      updateAuthUserInStorageAndStore: function (user) {
        let currentAurt = this.$Auth.get()
        currentAurt['user'] = user
        this.$Storage.set('f777bbf0-ef5d-45db-b77a-dd7802f92824', currentAurt)
        this.setStorageUserInStoreUser()
        this.$forceUpdate()
      }
    },
    created: function () {
      this.setStorageUserInStoreUser()
      this.getDarkFromStorage()
      this.getAuthUserDataUpdated()
      this.subscriptionNotifications()
      this.getTalks()
      this.subscribeOnTalks()
    },
    components: {
      DrawerLeft,
      AppBar,
      DrawerRight,
      DrawerTalks,
      Components
    }
  }
</script>
<style>
  @media only screen and (min-width: 960px) {
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    ::-webkit-scrollbar-thumb {
      background: #888; 
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }
  }
</style>