<template>
  <v-card class="elevation-17">
    <div v-if="data && data.items && data.items.length > 0">
      <v-progress-linear
        v-if="orderLoading"
        :height="4"
        :indeterminate="true"
        style="
          width: 100%;
          margin: 0;
        "
      ></v-progress-linear>
        <table
          dense
          class="business-items__table elevation-0"
        >
          <thead>
            <tr style="height: 40px;">
              <th
                class="hidden-xs-only hidden-sm-only"
                v-for="(header, headerIndex) in headers"
                :key="`header__${headerIndex}`"
                style="text-align: left;margin: 0 !important; padding: 5px !important;"
              >
                {{ header.text }}
              </th>
            </tr>
            
          </thead>
          
          <Container
            tag="tbody"
            @drop="onDrop"
            drag-handle-selector=".column-drag-handle"
            drag-class="opacity-ghost"
            drop-class="opacity-ghost-drop"
          >
            <Draggable v-for="(item, itemIndex) in items" :key="`${item.item.id}--${itemIndex}`" tag="tr">
              <td class="px-0" style="margin-top: 0px;">
                <div class="justify-left layout">
                  <v-tooltip
                    v-if="data.stage == 1"
                    class="hidden-xs-only hidden-sm-only"
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="primary"
                        class="column-drag-handle"
                      >
                        drag_handle
                      </v-icon>
                    </template>
                    <span>
                      Arraste para reordenar
                    </span>
                  </v-tooltip>

                  <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" >
                        <v-icon>settings</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-tile 
                        v-if="item.item.type == 1" 
                        :disabled="data.stage != 1"
                        @click="$WiEditDialog({
                          wiConfig: config.store + '-item-product',
                          onSubmit: refresh,
                          data: {
                            ...item,
                            business: data
                          },
                          redirectOnSubmit: false
                        })"
                      >
                        <v-list-tile-avatar>
                          <v-avatar>
                            <v-icon color="orange">edit</v-icon>
                          </v-avatar>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Editar</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile 
                        v-if="item.item.type == 2" 
                        :disabled="data.stage != 1"
                        @click="$WiEditDialog({
                          wiConfig: config.store + '-item-service',
                          onSubmit: refresh,
                          data: {
                            ...item,
                            business: data
                          },
                          redirectOnSubmit: false
                        })"
                      >
                        <v-list-tile-avatar>
                          <v-avatar>
                            <v-icon color="orange">edit</v-icon>
                          </v-avatar>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Editar</v-list-tile-title>
                      </v-list-tile>
                      <v-divider></v-divider>
                      <v-list-tile
                        :disabled="data.stage != 1"
                        @click="$WiApiDelete({
                          uri: 'sales/budget/item',
                          id: item.id,
                          callback: refresh,
                        })"
                      >
                        <v-list-tile-avatar>
                          <v-avatar>
                            <v-icon color="red">delete</v-icon>
                          </v-avatar>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Apagar</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </div>
              </td>
              <td style=" font-size: 11px;">
                {{ item.item.name || '-' }}
              </td>
              <td class="hidden-xs-only hidden-sm-only" style=" font-size: 11px;">
                <span v-if="item.category && item.category.name">
                  <span>{{ item.category.name }}</span>
                  <span v-if="item.description"> - </span>
                </span>
                <span>{{ item.description }}</span>
              </td>
              <td class="hidden-xs-only hidden-sm-only" style="">
                {{ item.quantity | float }}
              </td>
              <td class="hidden-xs-only hidden-sm-only" style="">
                {{ item.item.unit.code || '-' }}
              </td>
              <td class="hidden-xs-only hidden-sm-only" style="">
                {{ item.amount_unit | money }}
              </td>
              <td class="hidden-xs-only hidden-sm-only" style="">
                {{ item.amount_discount | money }}
              </td>
              <td style="">
                {{ item.amount_total | money }}
              </td>
            </Draggable>
          </Container>
          <tr class="hidden-xs-only hidden-sm-only" style="height: 40px;">
            <td class="justify-center layout px-0" style="margin-top: 0px;"></td>
            <td style="padding: 0 !important; font-size: 11px;"></td>
            <td style="padding: 0 !important; font-size: 11px;"></td>
            <td style="padding: 0 !important;"><strong>{{ data.sum_quantity_unit | float }}</strong></td>
            <td style="padding: 0 !important; font-size: 11px;"></td>
            <td style="padding: 0 !important;"><strong>{{ data.sum_amount_unit | money }}</strong></td>
            <td style="padding: 0 !important;"><strong>{{ data.sum_discount_unit | money }}</strong></td>
            <td style="padding: 0 !important;"><strong>{{ data.sum_amount_total | money }}</strong></td>
          </tr>
        </table>
      
      <v-divider></v-divider>
      <v-layout row wrap style="padding-top: 15px; padding-bottom: 15px;">
        <v-flex xs12 sm12 md7 lg7>
          <div style="width: 100%; text-align: center;">
            <div style="text-align: center;">
              <v-btn
                v-if="!config.hideProducts"
                :disabled="data.stage != 1"
                outline small
                color="primary"
                @click="$WiEditDialog({wiConfig: config.store + '-item-product', onSubmit: refresh, data: {business_id: data.id, business: data}, redirectOnSubmit: false})"
              >
                <v-icon>add</v-icon>
                <span>Adicionar Produto</span>
              </v-btn>
              <v-btn
                v-if="!config.hideServices"
                :disabled="data.stage != 1"
                outline small
                color="primary"
                @click="$WiEditDialog({wiConfig: config.store + '-item-service', onSubmit: refresh, data: {business_id: data.id, business: data}, redirectOnSubmit: false})"
              >
                <v-icon>add</v-icon>
                <span>Adicionar Serviço</span>
              </v-btn>
            </div>
          </div>
        </v-flex>
        <v-flex xs12 sm12 md5 lg5>
          <table style="width: 100%;">
            <tr>
              <td class="business-items__table-summary--left" style="border-top: solid 1px lightgray !important;">
                <strong style="padding: 10px;">
                  Valor Total:
                </strong>
              </td>
              <td class="business-items__table-summary--right" style="border-top: solid 1px lightgray !important;">
                <span style="padding: 10px; font-size: 16px;">
                  {{ data.sum_amount_total | money }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="business-items__table-summary--left">
                <strong style="padding: 10px;">
                  Desconto:
                </strong>
              </td>
              <td class="business-items__table-summary--right">
                <span style="padding: 10px; font-size: 16px;">
                  {{ data.amount_discount | money }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="business-items__table-summary--left">
                <strong style="padding: 10px;"><span v-text="config.amount_freight || 'Frete/Deslocamento'"></span>:</strong>
              </td>
              <td class="business-items__table-summary--right">
                <span style="padding: 10px; font-size: 16px;">
                  {{ data.amount_freight | money }}
                </span>
              </td>
            </tr>
            <tr v-if="data.coupon_id && data.coupon">
              <td class="business-items__table-summary--left">
                <strong style="padding: 10px;">
                  <span>Cupom de Desconto</span>:</strong>
                </td>
              <td class="business-items__table-summary--right">
                <span style="padding: 10px; font-size: 16px;">
                  -{{ data.coupon.amount | money }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="business-items__table-summary--left">
                <strong style="padding: 10px; font-size: 20px;">
                  Total líquido:
                </strong>
              </td>
              <td class="business-items__table-summary--right">
                <strong style="padding: 10px; font-size: 20px;">
                  {{ data.total_liquid | money }}
                </strong>
              </td>
            </tr>
          </table>
        </v-flex>
      </v-layout>
    </div>
    <div v-else style="text-align: center; padding: 20px;">
      <h3>Nenhum <span v-if="!config.hideProducts">Produto ou</span> Serviço vinculado a este(a) {{config.idTitle}}.</h3>
      <v-btn
        v-if="!config.hideProducts"
        :disabled="data.stage != 1"
        outline small
        color="primary"
        @click="$WiEditDialog({wiConfig: config.store + '-item-product', onSubmit: refresh, data: {business_id: data.id, business: data}, redirectOnSubmit: false})"
      >
        <v-icon>add</v-icon>
        <span>Adicionar Produto</span>
      </v-btn>
      <v-btn
        v-if="!config.hideServices"
        :disabled="data.stage != 1"
        outline small
        color="primary"
        @click="$WiEditDialog({wiConfig: config.store + '-item-service', onSubmit: refresh, data: {business_id: data.id, business: data}, redirectOnSubmit: false})"
      >
        <v-icon>add</v-icon>
        <span>Adicionar Serviço</span>
      </v-btn>
    </div>
  </v-card>   
</template>
<script>
  import { Container, Draggable } from 'vue-smooth-dnd'
  import { applyDrag } from '@/default/components/utils/helpers'
  export default {
    name: 'BusinessItems',
    data () {
      return {
        headers: [
          { text: '', value: 'id', sortable: false },
          { text: this.config.hideProducts ? 'Serviços' : 'Produtos/Serviços', value: 'nome_produto' },
          { text: 'Descrição', value: 'desc_negocio_produto' },
          { text: 'Qtd.', value: 'qtd_produto' },
          { text: 'Unid.', value: 'code' },
          { text: 'Val. Unit.', value: 'valor_unit' },
          { text: 'Desconto', value: 'valor_desc' },
          { text: 'Val. Total', value: 'valor_total' }
        ],
        orderLoading: false,
        items: [],
        filters: this.$options.filters,
        debounce: null
      }
    },
    methods: {
      onDrop: function (dropResult) {
        this.items = applyDrag(this.items, dropResult)
        this.orderLoading = true
        // Debounce
        if (this.debounce) {
          clearTimeout(this.debounce)
          this.debounce = null
        }
        this.debounce = setTimeout(() => {
          this.updateItemsOrder()
        }, 1000)
      },
      updateItemsOrder: function () {
        this.$WiApiPut({
          uri: 'sales/budget/item',
          id: 'multiple',
          data: this.items.map((item, itemIndex) => {
            return {
              id: item.id,
              order: itemIndex+1
            }
          }),
          callback: () => {
            this.orderLoading = false
          }
        })
      },
    },
    mounted: function () {
      this.items = this.data.items
    },
    components: {
      Container,
      Draggable
    },
    props: {
      'config': {
        default: {}
      },
      'data': {
        default: {}
      },
      'refresh': {
        required: true
      }
    }
  }
</script>
<style>
p {
  margin-bottom: 0px !important;
}
.finance_status {
  border-width: 1px;
  border-style: solid;
  padding: 2px 5px;
}
.canceled {
  color: grey;
  background: #eceff1;
}
.unpaid {
  color: #ff6d00;
  background: #f0f4c3;
}
.paid {
  color: green;
  background: #b9f6ca;
}
.parcel {
  color: white;
  background: #000;
}
.pointer {
  cursor: pointer;
}
.v-datatable__actions {
  bottom: 0;
  position: fixed;
  width: 100%;
  left: 0;
  height: 40px;
}
.v-datatable__actions__select {
  height: 30px;
}
.v-datatable__actions__range-controls {
  min-height: 30px;
}
.v-datatable__actions .v-btn {
  height: 30px;
}
.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    overflow: hidden;
    display: table-row !important;
}
.business-items__table {
  width: 100%;
  border-collapse: collapse;
}
.business-items__table td {
  border-top: 1px solid #ddd;
  padding: 0 5px !important;
}
.smooth-dnd-draggable-wrapper.animated {
  background: lightgray !important;
  width: 100% !important;
}
.business-items__table-summary--left {
  padding: 0px 0px;
  text-align: left;
  border-bottom: solid 1px lightgray !important;
}
.business-items__table-summary--right {
  padding: 0px 0px;
  text-align: right;
  border-bottom: solid 1px lightgray !important;
}
</style>
