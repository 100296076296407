export class Notifications {
  static format (notification) {
    if (Array.isArray(notification)) {
      return this.formatNotifications(notification)
    } else {
      return this.formatNotification(notification)
    }
  }
  static formatNotification (notification) {
    return {
      ...notification,
      ...this.getType(notification.data.type)
    }
  }
  static formatNotifications (notifications) {
    return notifications.map(notification => {
      return this.formatNotification(notification)
    })
  }
  static desktopNotification (notification) {
    notification = this.formatNotification(notification)
    new Notification(notification.data.creator.name, {
      body: notification.text(notification),
      icon: notification.data.creator.image || 'static/icons/user_icon_1.png'
    }).onclick = function () {
      window.location.href = window.location.href + '?notification=' + notification.id
    }
  }
  static getType (type) {
    let types = {
      'test': {
         text: () => {
          return ` criou uma notificação de teste. Favor ignorá-la.`
        },
        route: () => {
          return {name: 'dashboard'}
        }
      },
      'ticket.created': {
         text: (notification) => {
          return ` criou o chamado #${notification.data.ticket_id} ao qual você está envolvido.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        }
      },
      'ticket.created.responsible': {
         text: (notification) => {
          return ` criou o chamado #${notification.data.ticket_id} e colocou você como responsável.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        }
      },
      'ticket.created.team': {
         text: (notification) => {
          return ` criou o chamado #${notification.data.ticket_id} para sua equipe.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        }
      },
      'ticket.changed': {
         text: (notification) => {
          return ` alterou o chamado #${notification.data.ticket_id} em que você está envolvido.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        }
      },
      'ticket.changed.creator': {
         text: (notification) => {
          return ` alterou o chamado #${notification.data.ticket_id} em que você é o solicitante.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        }
      },
      'ticket.changed.responsible': {
         text: (notification) => {
          return ` alterou o chamado #${notification.data.ticket_id} ao qual você é o responsável.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        }
      },
      'ticket.changed.old-responsible': {
         text: (notification) => {
          return ` transferiu o chamado #${notification.data.ticket_id} ao qual você era o responsável.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        }
      },
      'ticket.changed.follower': {
         text: (notification) => {
          return ` alterou o chamado #${notification.data.ticket_id} que você está seguindo.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        }
      },
      'ticket.changed.team': {
         text: (notification) => {
          return ` alterou o chamado #${notification.data.ticket_id} vinculado a sua equipe.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        }
      },
      'ticket.responsible': {
         text: (notification) => {
          return ` colocou você como responsável em no chamado #${notification.data.ticket_id}.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        }
      },
      'ticket.follower': {
         text: (notification) => {
          return ` colocou você como seguidor no chamado #${notification.data.ticket_id}.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        }
      },
      'ticket.team': {
         text: (notification) => {
          return ` alterou o chamado #${notification.data.ticket_id} de sua equipe.`
        },
        route: (notification) => {
          return {name: 'ticket', params: {id: notification.data.ticket_id}}
        }
      },
      'talk.message': {
         text: () => {
          return ` Enviou uma nova mensagem no WhatsApp.`
        },
        route: (notification) => {
          return {name: 'talk-view-messages', params: {id: notification.data.talk_id}}
        }
      },
      'talk.group.message': {
         text: () => {
          return ` Nova mensagem no grupo.`
        },
        route: (notification) => {
          return {name: 'talk-view-messages', params: {id: notification.data.talk_id}}
        }
      },
      'talk.changed.transfer': {
         text: () => {
          return ` Transferiu um atendimento para você.`
        },
        route: (notification) => {
          return {name: 'talk-view-messages', params: {id: notification.data.talk_id}}
        }
      }
    }
    return types[type]
  }
}