<template>
  <WiView v-if="apiParams" index="talks-dashboard" apiUrl="talks/report/dashboard" :apiParams="apiParams" :notShowToolbar="true" :notGetID="true" @afterLoad="setContent">
    <template slot-scope="{ content, refresh }">
      <v-container style="padding: 10px;" fluid grid-list-md>
        <v-layout row wrap>
          <v-flex sm12 style="padding: 5px 5px 10px 5px;">
            <v-toolbar dense>
              <v-btn icon ripple @click="$router.go(-1)">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-list-tile v-ripple avatar class="hidden-xs-only hidden-sm-only">
                <v-list-tile-avatar @click="refresh()" style="cursor: pointer;" size="45">
                  <img src="static/systems/modules/wises-technology-module-talk.png" alt="Dashboard Atendimentos">
                </v-list-tile-avatar>
                <v-list-tile-content @click="refresh()" style="cursor: pointer;">
                  <v-list-tile-sub-title style="font-size: 12px;">Módulo</v-list-tile-sub-title>
                  <v-list-tile-title style="font-size:18px;">
                    <span>Atendimentos</span>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" outline small>
                    <span class="pr-2 hidden-xs-only hidden-sm-only">Data inicial: </span>
                    <strong class="pr-2">{{ dateStart | moment('DD/MM/YY HH:mm') }}</strong>
                    <span class="pr-2 hidden-xs-only hidden-sm-only">Data final: </span>
                    <strong>{{ dateEnd | moment('DD/MM/YY HH:mm') }}</strong>
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dense flat color="transparent">
                    <v-toolbar-title>Insira uma data inicial e final.</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog = false">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-text-field
                      dense
                      outline
                      v-model="dateStart"
                      type="datetime-local"
                      label="Data Inicial"
                    ></v-text-field>
                    <br>
                    <v-text-field
                      dense
                      outline
                      v-model="dateEnd"
                      type="datetime-local"
                      label="Data Final"
                    ></v-text-field>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn block outline color="green" @click="changeFilters()">
                      <v-icon>filter_list</v-icon>
                      <span>filtrar</span>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-btn flat icon color="green" @click="refresh()">
                <v-icon>refresh</v-icon>
              </v-btn>
            </v-toolbar>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12>
            <span>*Dados obtidos em {{ content.cached_at | moment('DD/MM/YY à\\s HH:mm:ss') }}.</span>
            <span v-if="cacheExpiredAt >= 0"> Novos dados disponíveis em {{ cacheExpiredAt | number }} segundos ({{ content.cached_end_at | moment('DD/MM/YY à\\s HH:mm:ss') }}).</span>
            <span v-else @click="refresh()" style="cursor: pointer;"> Clique aqui para obter novos dados.</span>
          </v-flex>
          <v-flex xs12 sm12 md3 lg2>
            <DashboardTalksCard
              title="Entrantes"
              icon="format_list_bulleted"
              color="#73bcf4"
              :data="content.statistics.everyone_in_queue | number"
            ></DashboardTalksCard>
          </v-flex>
          <v-flex xs12 sm12 md3 lg2>
            <DashboardTalksCard
              title="Atendimentos"
              icon="checklist"
              color="#70d174"
              :data="content.statistics.attendance_rate | number"
            ></DashboardTalksCard>
          </v-flex>
          <v-flex xs12 sm12 md3 lg2>
            <DashboardTalksCard
              title="Abandonos"
              icon="playlist_remove"
              color="#f97970"
              :data="content.statistics.abandonment_rate | number"
            ></DashboardTalksCard>
          </v-flex>
          <v-flex xs12 sm12 md3 lg2>
            <DashboardTalksCard
              title="Média Duração"
              icon="schedule"
              color="#ffbb5b"
              :data="content.statistics.average_time"
            ></DashboardTalksCard>
          </v-flex>
          <v-flex xs12 sm12 md3 lg2>
            <DashboardTalksCard
              title="Maior Duração"
              icon="more_time"
              color="#c289ff"
              :data="content.statistics.max_time"
            ></DashboardTalksCard>
          </v-flex>
          <v-flex xs12 sm12 md3 lg2>
            <DashboardTalksCard
              title="Menor Duração"
              icon="history_toggle_off"
              color="#5dd8d4"
              :data="content.statistics.min_time"
            ></DashboardTalksCard>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 class="mt-2">
            <v-card>
              <v-card-title>
                <h4>Equipes/departamentos</h4>
              </v-card-title>
              <v-divider></v-divider>
              <v-data-table
                :headers="headersTeams"
                :items="content.teams"
                disable-initial-sort
                :rows-per-page-items="[50, 5, 10, 100, 200]"
                rows-per-page-text="Ítens por página"
              >
                <template v-slot:items="props">
                  <td>
                    <strong>{{ props.item.name }}</strong>
                  </td>
                  <td class="text-xs-left">
                    <h2>{{ props.item.talks }}</h2>
                  </td>
                  <td class="text-xs-left">
                    <h3>{{ props.item.average_time }}</h3>
                  </td>
                  <td class="text-xs-left">
                    <h3>{{ props.item.max_time }}</h3>
                  </td>
                  <td class="text-xs-left">
                    <h3>{{ props.item.min_time }}</h3>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 class="mt-2">
            <v-card>
              <v-card-title>
                <h4>Colaboradores</h4>
              </v-card-title>
              <v-divider></v-divider>
              <v-data-table
                :headers="headersOperators"
                :items="content.operators"
                disable-initial-sort
                :rows-per-page-items="[50, 5, 10, 100, 200]"
                rows-per-page-text="Ítens por página"
              >
                <template v-slot:items="props">
                  <td>
                    <strong>{{ props.item.name }}</strong>
                  </td>
                  <td class="text-xs-left">
                    <h2>{{ props.item.talks }}</h2>
                  </td>
                  <td class="text-xs-left">
                    <h3>{{ props.item.average_time }}</h3>
                  </td>
                  <td class="text-xs-left">
                    <h3>{{ props.item.max_time }}</h3>
                  </td>
                  <td class="text-xs-left">
                    <h3>{{ props.item.min_time }}</h3>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </WiView>
</template>
<script>
import DashboardTalksCard from './DashboardCard'

const headers = [
  { text: 'Atendimentos', value: 'talks' },
  { text: 'Duração média', value: 'average_time' },
  { text: 'Duração Máxima', value: 'max_time' },
  { text: 'Duração Mínima', value: 'min_time' }
]

export default {
  name: 'dashboard-talks',
  data: () => ({
    dialog: false,
    content: {},
    refresh: null,
    apiParams: null,
    dateStart: null,
    dateEnd: null,
    headersOperators: [
      {
        text: 'Operador',
        align: 'left',
        value: 'name'
      },
      ...headers
    ],
    headersTeams: [
      {
        text: 'Equipe/Departamento',
        align: 'left',
        value: 'name'
      },
      ...headers
    ],
    cacheExpiredAt: 0,
    cacheExpiredAtIntercal: null
  }),
  methods: {
    setContent: function ({ content, refresh }) {
      this.content = content
      this.refresh = refresh
      this.setCacheExpiredAt(content?.cache_expire_in || 0)
    },
    setInitialDateInterval: function () {
      this.dateStart = this.$DateTime.date() + 'T00:00:00'
      this.dateEnd = this.$DateTime.date() + 'T23:59:59'
    },
    setApiParams: function () {
      if (this.dateStart && this.dateEnd) {
        this.apiParams = `?dateStart=${this.dateStart}&dateEnd=${this.dateEnd}`
        this.$forceUpdate()
      }
    },
    changeFilters: function () {
      this.setApiParams()
      setTimeout(() => {
        this.refresh()
        this.dialog = false
      }, 50);
    },
    setCacheExpiredAt: function (cacheExpiredAt) {
      this.cacheExpiredAt = cacheExpiredAt
      clearInterval(this.cacheExpiredAtIntercal)
      this.cacheExpiredAtIntercal = setInterval(() => {
        this.cacheExpiredAt = this.cacheExpiredAt - 1
      }, 1000);
    }
  },
  created: function () {
    this.setInitialDateInterval()
    this.setApiParams()    
  },
  components: {
    DashboardTalksCard
  }
}
</script>