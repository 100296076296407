<template>
  <view-detail
    index="order"
    title="Detalhes da Ordem de Serviço"
    apiUrl="service-desk/order"
    apiParams="?with=events;finances;status:id,name,icon,color;category:id,name,icon,color,form_id;category.form;items;items.item:id,name,type,item_unit_id;items.item.unit:id,code;business:id,type,form_reply_id,created_at,business_category_id;business.category.form;businesses:id,type,business_id,created_at"
    store="order"
  >
    <template slot="toolbarItensBefore" slot-scope="{ content, refresh, loading }">
      <BusinessActions
        v-if="content"
        :business="content"
        :refresh="refresh"
        :loading="loading"
      ></BusinessActions>
    </template>
    <template slot-scope="{ content, refresh, loading }">
      <v-container fluid :style="$route.meta.scroll ? { 'height': height } : {}" style="overflow: auto">
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessRelationships
              :business="content"
              :refresh="refresh"
              :loading="loading"
            ></BusinessRelationships>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessInfo
              :info="configuration"
              :data="content"
            ></BusinessInfo>
          </v-flex>
          <v-flex v-if="content.person && content.person.id" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessAddress
              title="Endereço de Execução"
              :business="content"
              :person="content.person || {}"
              :address="content.address || {}"
              @onSubmit="refresh"
            ></BusinessAddress>
          </v-flex>
          <v-flex v-if="content.category.form && content.category.form.id" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessForm
              :refresh="refresh"
              :reply="content.reply"
              :businessId="content.id"
              :form="content.category.form"
              :replyEditor="content.reply_editor"
            ></BusinessForm>
          </v-flex>
          <v-flex v-if="content.observation" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessObservation
              :observation="content.observation"
            ></BusinessObservation>
          </v-flex>
          <v-flex v-if="!$WiCheckPermission(PERMISSION_INTERNET_MODULE)" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessItems
              :business="content"
              wiConfigId="sale"
              :onRefresh="refresh"
              :showAmounts="false"
            ></BusinessItems>
          </v-flex>
          <v-flex 
            v-if="
              content.business &&
              content.business.category &&
              content.business.category.form &&
              content.business.category.form.id
            " 
            xs12 sm12 md12 lg12 
            style="padding-bottom: 20px;"
          >
            <BusinessForm
              :refresh="refresh"
              :reply="content.business.reply"
              :businessId="content.business.id"
              :businessBusinessId="content.id"
              :form="content.business.category.form"
              :replyEditor="content.business.reply_editor"
            ></BusinessForm>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </view-detail>
</template>
<script>
  import { PERMISSION_INTERNET_MODULE } from '@/default/constants/permissions'
  import BusinessMixin from '@/modules/business/mixins/BusinessMixin'
  import BusinessInfo from '@/default/component/WiView/WiViewDashboard'
  import BusinessActions from '@/modules/business/pages/Business/BusinessActions'
  import BusinessForm from '@/modules/business/pages/Business/BusinessForm'
  import BusinessAddress from '@/modules/business/pages/Business/BusinessAddress'
  import BusinessItems from '@/modules/business/pages/Business/BusinessItems'
  import BusinessObservation from '@/modules/business/pages/Business/BusinessObservation'
  import BusinessRelationships from '@/modules/business/pages/Business/BusinessRelationships'
  export default {
    name: 'Order',
    data: () => ({
      height: 0,
    }),
    computed: {
      PERMISSION_INTERNET_MODULE () {
        return PERMISSION_INTERNET_MODULE
      }
    },
    methods: {
      setContractLabelsText: function () {
        this.labels.category.text = 'Categoria/Tipo de Ordem:'
        this.labels.dateStart.text = 'Data de Referência:'
        this.labels.dateEnd.text = 'Previsão de execução:'
        this.labels.duration.text = 'Duração:'
      }
    },
    created: function () {
      this.height = `${(window.innerHeight - 130)}px`
      this.setContractLabelsText()
    },
    components: {
      BusinessInfo,
      BusinessForm,
      BusinessActions,
      BusinessAddress,
      BusinessObservation,
      BusinessItems,
      BusinessRelationships
    },
    mixins: [BusinessMixin]
  }
</script>
