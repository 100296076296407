import User from '@/default/config/User/User'
import UserPassword from '@/default/config/User/UserPassword'
import UserProfile from '@/default/config/User/UserProfile'
import NotificationPerson from '@/default/config/Notification/NotificationPerson'
import { File as FileConfig } from '@/default/config/Common/File'
import { Log } from '@/default/config/Common/Log'
import { LayoutVariable } from '@/modules/wells/config/Config/LayoutVariable'

export default {
  'log': new Log(),
  'file': new FileConfig(),
  'user': User,
  'user-password': UserPassword,
  'user-profile': UserProfile,
  'notifications': NotificationPerson,
  'business-layout-variables': new LayoutVariable()
}
