import { Auth } from '@/default/service/Auth'
import { callApi } from '@/default/service/Api'
import { Notifications } from '@/default/service/Notifications'

// const talkTeamIdHasInSelectedTeams = (teams, talkTeamId) => {
//   return !talkTeamId || (teams.filter(team => team.selected).findIndex(team => team.id === talkTeamId) > -1)
// }

export default {
  namespaced: true,
  state: {
    talks: [],
    teams: [],
    channels: [],
    operators: [],
    page: 1,
    loadings: {
      load: false,
      paginate: false,
      operators: false
    },
    selectedOperator: null
  },
  getters: {
    queue (state, getters) {
      return state.talks.filter(talk => (
        talk.status < 3 &&
        !talk.operator_id &&
        (state.channels.findIndex(channel => channel.id == talk.talk_channel_id) > -1) &&
        (!talk.team_id || getters.selectedTeams.findIndex(team => team.id === talk.team_id) > -1)
      ))
    },
    myTalks (state) {
      return state.talks.filter(talk => (
        talk.status < 3 &&
        talk.operator_id === Auth.user().id 
      ))
    },
    others (state, getters) {
      return state.talks.filter(talk => (
        talk.status < 3 &&
        talk.operator_id &&
        talk.operator_id !== Auth.user().id &&
        talk.type === 1 &&
        (state.channels.findIndex(channel => channel.id == talk.talk_channel_id) > -1) &&
        (!talk.team_id || getters.selectedTeams.findIndex(team => team.id === talk.team_id) > -1)
      ))
    },
    groups (state, getters) {
      return state.talks.filter(talk => (
        talk.status < 3 &&
        talk.operator_id &&
        talk.operator_id !== Auth.user().id &&
        talk.type === 2 &&
        (state.channels.findIndex(channel => channel.id == talk.talk_channel_id) > -1) &&
        (!talk.team_id || getters.selectedTeams.findIndex(team => team.id === talk.team_id) > -1)
      ))
    },
    byOperator (state, getters) {
      if (state.selectedOperator === null) {
        return []
      }
      return state.talks.filter(talk => (
        talk.operator_id === state.selectedOperator.id &&
        (state.channels.findIndex(channel => channel.id == talk.talk_channel_id) > -1) &&
        (!talk.team_id || getters.selectedTeams.findIndex(team => team.id === talk.team_id) > -1)
      ))
    },
    unreadsQueue (state, getters) {
      return getters.queue.length
    },
    unreadsMyTalks (state, getters) {
      return getters.myTalks.filter(talk => talk.unseen_messages > 0).length
    },
    unreadsOthers (state, getters) {
      return getters.others.filter(talk => talk.unseen_messages > 0).length
    },
    unreadsGroups (state, getters) {
      return getters.groups.filter(talk => talk.unseen_messages > 0).length
    },
    selectedTeams (state) {
      return state.teams.filter(team => team.selected)
    }
  },
  mutations: {
    SET_TALKS (state, talks) {
      state.talks = talks
      state.page = 1
    },
    SET_TEAMS (state, teams) {
      state.teams = teams.map(team => ({
        ...team,
        selected: true
      }))
    },
    SET_CHANNELS (state, channels) {
      state.channels = channels.map(channel => ({
        ...channel,
        selected: true
      }))
    },
    SET_OPERATORS (state, operators) {
      state.operators = operators
    },
    ADD_TALK (state, talk) {
      state.talks.unshift(talk)
    },
    ADD_TALK_TO_END (state, talk) {
      state.talks.push(talk)
    },
    UPDATE_TALK (state, {key, talk}) {
      state.talks[key].unseen_messages = talk.unseen_messages
      if (state.talks[key].last_event_at !== talk.last_event_at) {
        state.talks.splice(key, 1)
        state.talks.unshift(talk)
      } else {
        state.talks.splice(key, 1, talk)
      }
    },
    INCREMENT_PAGE (state) {
      state.page = state.page + 1
    },
    TOUGGLE_LOADING (state, loading) {
      state.loadings[loading] = !state.loadings[loading]
    },
    TOUGGLE_TEAM (state, team) {
      const key = state.teams.findIndex(t => (t.id == team.id))
      state.teams[key].selected = team.selected
    },
    SELECTED_OPERATOR (state, operator) {
      state.page = 1
      state.selectedOperator = operator
    }
  },
  actions: {
    setTalk ({ state, commit, dispatch }, {action, talk}) {
      const key = state.talks.findIndex(t => (t.id == talk.id))
      if (key > -1) {
        dispatch('checkNotificationOnUpdateTalk', {key: key, talk: talk})
        commit('UPDATE_TALK', {key: key, talk: talk})
      } else {
        if (action === 'end') {
          commit('ADD_TALK_TO_END', talk)
        } else {
          commit('ADD_TALK', talk)
          // dispatch('checkNotification', talk)
        }
      }
    },
    getTalks ({ state, commit }) {
      const selectedOperator = state.selectedOperator
      commit('TOUGGLE_LOADING', 'load')
      callApi.get({
        uri: 'talks/talk-start',
        params: {
          order: 'last_event_at,desc',
          where: 'status,<,3',
          limit: 20,
          ...(selectedOperator ? { operator_id: selectedOperator.id } : {})
        },
        sucess: ({ data }) => {
          if (data.talks && data.talks.length > 0) {
            commit('SET_TALKS', data.talks)
          } else {
            commit('SET_TALKS', [])
          }
          if (data.teams && data.teams.length > 0) {
            commit('SET_TEAMS', data.teams)
          } else {
            commit('SET_TEAMS', [])
          }
          if (data.channels && data.channels.length > 0) {
            commit('SET_CHANNELS', data.channels)
          } else {
            commit('SET_CHANNELS', [])
          }
          commit('TOUGGLE_LOADING', 'load')
        },
        error: () => {
          commit('TOUGGLE_LOADING', 'load')
        }
      })
    },
    getOperators ({ commit }) {
      commit('TOUGGLE_LOADING', 'operators')
      callApi.get({
        uri: 'talks/by-operators',
        params: {},
        sucess: ({ data }) => {
          commit('SET_OPERATORS', data)
          commit('TOUGGLE_LOADING', 'operators')
        },
        error: () => {
          commit('TOUGGLE_LOADING', 'operators')
        }
      })
    },
    subscribeOnTalks ({ dispatch }) {
      if (Auth.checkPermission('123eab35-8984-4551-bd15-5b054b2ac8bf')) {
        window.Echo.private(Auth.account().id + '.talks')
        .listen('.new', (talk) => {
          dispatch('setTalk', { talk: talk })
        })
        .listen('.change', (talk) => {
          dispatch('setTalk', { talk: talk })
        })
      }
    },
    paginateTalks ({ state, commit, dispatch }, type) {
      const selectedOperator = state.selectedOperator
      commit('TOUGGLE_LOADING', 'paginate')
      callApi.get({
        uri: 'talks/talk',
        params: {
          order: 'last_event_at,desc',
          where: 'status,<,3',
          limit: 20,
          page: state.page,
          type: type,
          ...(selectedOperator ? { operator_id: selectedOperator.id } : {})
        },
        sucess: response => {
          response.data.data.map(talk => {
            dispatch('setTalk', { action: 'end', talk: talk })
          })
          commit('INCREMENT_PAGE')
          commit('TOUGGLE_LOADING', 'paginate')
        },
        error: () => {
          commit('TOUGGLE_LOADING', 'paginate')
        }
      })
    },
    checkNotification ({ state, dispatch }, talk) {
      if (!talk.operator_id || talk.operator_id === Auth.user().id || talk.responsible_id === Auth.user().id) {
        if (
          Auth.checkPermission('123eab35-8984-4551-bd15-5b054b2ac8bf') && 
          parseInt(talk.last_message_direction) === 1 && 
          !talk.muted && 
          (state.channels.findIndex(channel => channel.id == talk.talk_channel_id) > -1)
        ) {
          dispatch('generateNotification', talk)
        }
      }
    },
    checkNotificationOnUpdateTalk ({ state, dispatch, rootState }, {talk, key}) {
      if (
        talk.id != rootState.Talk.talk.id && 
        state.talks[key].last_event_at !== talk.last_event_at && 
        (state.channels.findIndex(channel => channel.id == talk.talk_channel_id) > -1)
      ) {
        dispatch('checkNotification', talk)
      }
    },
    generateNotification (root, talk) {
      let notificationType = "talk.message"
      if (talk.contact.type === 2) {
        notificationType = "talk.group.message"
      }
      let notification = {
        "id": talk.uuid,
        "data":{
          "talk_id": talk.id,
          "creator": {
            ...talk.contact,
            image: talk.contact.file ? talk.contact.file.url : '/static/icons/monitor/whatsapp.png'
          },
          "type": notificationType
        },
        "read_at": null,
        "created_at": talk.last_event_at
      }
      notification = Notifications.format(notification)
      Notifications.desktopNotification(notification)
    }
  }
}