// Quotation
import Quotation from '@/modules/purchases/config/Quotation/Quotation'

// Purchase
import Purchase from '@/modules/purchases/config/Purchase/Purchase'

// Common
import Event from '@/modules/purchases/config/Common/Event'
import Item from '@/modules/purchases/config/Common/Item'
import Finances from '@/modules/purchases/config/Common/Finance'
import { BusinessFinance } from '@/modules/shared/config/Business/BusinessFinance'

// Configurations
import Status from '@/modules/purchases/config/Config/Status'
import Category from '@/modules/purchases/config/Config/Category'

// Registers
import Product from '@/modules/purchases/config/Register/Product'
import Service from '@/modules/purchases/config/Register/Service'
import Customer from '@/modules/purchases/config/Register/Customer'
import Collaborator from '@/modules/purchases/config/Register/Collaborator'

export default {
  'purchase': Purchase.purchase,
  'purchase-open': Purchase.open,
  'purchase-close': Purchase.close,
  'purchase-cancel': Purchase.cancel,
  'purchase-event': Event.purchase,
  'purchase-item-product': Item.product.purchase,
  'purchase-item-service': Item.service.purchase,
  'purchase-finances': Finances.purchase,
  'purchase-finance': new BusinessFinance(),

  'quotation': Quotation.quotation,
  'quotation-open': Quotation.open,
  'quotation-close': Quotation.close,
  'quotation-cancel': Quotation.cancel,
  'quotation-event': Event.quotation,
  'quotation-item-product': Item.product.quotation,
  'quotation-item-service': Item.service.quotation,
  'quotation-finances': Finances.quotation,
  'quotation-finance': new BusinessFinance(),

  'config-status-purchase': Status.purchase,
  'config-category-purchase': Category.purchase,
  'config-status-quotation': Status.quotation,
  'config-category-quotation': Category.quotation,

  'purchases-register-product': Product,
  'purchases-register-service': Service,
  'purchases-register-customer': Customer,
  'purchases-register-collaborator': Collaborator
}
