export default {
  id: {
    id: 'b7454443',
    create: '233089f5',
    update: '5a9ddcc5',
    delete: 'da58132a'
  },
  list: true,
  title: {
    list: 'Produtos em Estoque',
    edit: {
      insert: 'Adicionando Produto ao Estoque',
      update: 'Editando Produto do Estoque'
    }
  },
  icon: 'move_to_inbox',
  search: {
    placeholder: 'Pesquise pelo ID, Nome do Produto ou Estoque',
    fields: ['item_id', 'item.name', 'stock.name']
  },
  apiUrl: 'stock/product',
  components: {
    list: {
      tableRow: () => import('@/modules/stock/pages/Stock/StockItems')
    }
  },
  size: ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1'],
  fields: [
    {
      value: 'item_id',
      text: 'ID',
      list: true
    },
    {
      value: 'item.name',
      text: 'Nome do Produto',
      list: true
    },
    {
      value: 'quantity',
      text: 'Qtd. Em Estoque',
      list: true
    },
    {
      value: 'stock.name',
      text: 'Estoque',
      list: true
    },
    {
      value: 'amount_cost',
      text: '$ de Compra',
      list: true
    },
    {
      value: 'amount_sale',
      text: '$ de Venda',
      list: true
    },
    {
      value: 'item_id',
      text: 'Opções',
      list: true
    },
    {
      text: 'Selecione o produto que deseja adicionar.',
      type: 'subheader',
      edit: true
    },
    {
      value: 'stock_id',
      text: 'Unidade de Estoque*',
      autocomplete: true,
      required: true,
      type: 'db-autocomplete',
      edit: {
        insert: true
      },
      apiUrl: 'stock',
      apiParams: {fields: 'id,name', order: 'name,asc'},
      show: 'name',
      size: ['xs12', 'sm12', 'md6', 'lg6']
    },
    {
      value: 'item_id',
      text: 'Produto*',
      autocomplete: true,
      required: true,
      type: 'db-autocomplete',
      edit: {
        insert: true
      },
      apiUrl: 'register/product',
      apiParams: {fields: 'id,name', order: 'name,asc'},
      show: 'name',
      size: ['xs12', 'sm12', 'md6', 'lg6']
    },
    {
      value: 'min',
      text: 'Qtd. Mínima em estoque',
      placeholder: 'Mínimo viável deste produto em estoque.',
      type: 'int',
      edit: true,
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3']
    },
    {
      value: 'max',
      text: 'Qtd. Máxima em estoque',
      placeholder: 'Máximo viável deste produto em estoque.',
      type: 'int',
      edit: true,
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3']
    },
    {
      value: 'amount_cost',
      text: 'Valor de Custo',
      type: 'money',
      edit: true,
      size: ['xs12', 'sm12', 'md3', 'lg3']
    },
    {
      value: 'amount_sale',
      text: 'Valor de Venda',
      type: 'money',
      edit: true,
      size: ['xs12', 'sm12', 'md3', 'lg3']
    }
  ]
}
