<template>
  <kanban 
    :title="config.title" 
    :store="config.store"
    :store-stage="config.storeStage"
    :api-get-url="config.getUrl" 
    :api-put-url="config.putUrl"
    field-reference-in-item="business_status_id"
    :message-alert-title="config.alertTitle"
    :message-alert-text="config.alertText"
    :router-click="config.routerClick"
  >
  <template slot="items" slot-scope="item">
    <v-card
      style="
        margin-bottom: 5px;
        cursor: grab;
        width: 100% !important;
        width: 270px;
        max-width: 270px;
        border-radius: 10px;
      "
    >
      <div @click="goRouter(item.item)">
        <v-card-title style="padding: 5px;">
          <span>Cod.: </span>
          <strong> #{{item.item.id}}</strong>
          <v-spacer></v-spacer>
          <span v-if="item.item.stage == 1">
            <strong
              v-if="(config.type == 1 || config.type == 2) && item.item.date_validity_days < 0"
              style="color: white; padding: 0px 5px; background: red;"
            >
              EXPIRADO
            </strong>
            <strong
              v-if="(config.type == 3 || config.type == 4) &&  item.item.date_end_days < 0"
              style="color: white; padding: 0px 5px; background: red;"
            >
              EXPIRADO
            </strong>
          </span>
        </v-card-title>
        <v-divider v-if="item.item.external_code"></v-divider>
        <v-card-title v-if="item.item.external_code" style="padding: 5px;">
          <span>
            <span>Cod. Externo: </span>
            <strong> {{item.item.external_code | stringlength(20)}}</strong>
          </span>
        </v-card-title>
        <v-divider v-if="item.item.person"></v-divider>
        <v-card-title v-if="item.item.person" style="padding: 5px;">
          <v-icon style="color:#1C8ADB">person</v-icon>
          <span>{{item.item.person.name | stringlength(27)}}</span>
        </v-card-title>
        <v-divider v-if="item.item.category"></v-divider>
        <v-card-title v-if="item.item.category" style="padding: 5px;">
          <v-icon :color="item.item.category.icon" :style="{color: item.item.category.color || '#000'}">{{item.item.category.icon}}</v-icon>
          <span>{{item.item.category.name | stringlength(27)}}</span>
        </v-card-title>
        <v-divider v-if="item.item.collaborator"></v-divider>
        <v-card-title v-if="item.item.collaborator" style="padding: 5px;">
          <v-img
            v-if="item.item.collaborator.file && item.item.collaborator.file.url"
            :src="item.item.collaborator.file.url"
            maxWidth="22" style="border-radius: 50%;"
          ></v-img>
          <v-icon v-else color="primary">support_agent</v-icon>
          <span style="padding-left: 5px;">{{item.item.collaborator.name | stringlength(27)}}</span>
        </v-card-title>

        <v-divider v-if="item.item.date_end_format"></v-divider>
        <v-card-title
          v-if="item.item.stage == 1 && item.item.date_end_format && item.item.date_end_days < 0"
          style="padding: 5px; color: red;"
        >
          <v-icon>date_range</v-icon>
          <label>
            <strong v-text="config.date_end || 'Entrega'"></strong>: {{item.item.date_end_format}}
          </label>
          <v-spacer></v-spacer>
          <strong style="padding: 0px 5px; border-style: solid; border-width: 1px; border-color: red;">
            A {{item.item.date_end_days * -1}} dia(s)
          </strong>
        </v-card-title>
        <v-card-title
          v-else-if="item.item.stage == 1 && item.item.date_end_format && item.item.date_end_days < 2"
          style="padding: 5px; color: orange;"
        >
          <v-icon>date_range</v-icon>
          <label><strong v-text="config.date_end || 'Entrega'"></strong>: {{item.item.date_end_format}}</label>
          <v-spacer></v-spacer>
          <strong
            style="padding: 0px 5px; border-style: solid; border-width: 1px; border-color: orange;"
          >
            <span v-if="item.item.date_end_days">
              Em {{item.item.date_end_days}} dia(s)
            </span>
            <span v-else>Hoje</span>
          </strong>
        </v-card-title>
        <v-card-title v-else-if="item.item.date_end_format" style="padding: 5px;">
          <v-icon>date_range</v-icon>
          <label><strong v-text="config.date_end || 'Entrega'"></strong>: {{item.item.date_end_format}}</label>
          <v-spacer></v-spacer>
          <strong
            v-if="item.item.stage == 1"
            style="padding: 0px 5px; border-style: solid; border-width: 1px;"
          >
            Em {{item.item.date_end_days}} dia(s)
          </strong>
        </v-card-title>

        <div v-if="config.type == 1 || config.type == 2">
          <v-divider v-if="item.item.date_validity_format"></v-divider>
          <v-card-title
            v-if="item.item.stage == 1 && item.item.date_validity_format && item.item.date_validity_days < 0"
            style="padding: 5px; color: red;"
          >
            <v-icon>date_range</v-icon>
            <label><strong>Validade</strong>: {{item.item.date_validity_format}}</label>
            <v-spacer></v-spacer>
            <strong style="padding: 0px 5px; border-style: solid; border-width: 1px; border-color: red;">A {{item.item.date_validity_days * -1}} dia(s)</strong>
          </v-card-title>
          <v-card-title
            v-else-if="item.item.stage == 1 && item.item.date_validity_format && item.item.date_validity_days < 2"
            style="padding: 5px; color: orange;"
          >
            <v-icon>date_range</v-icon>
            <label><strong>Validade</strong>: {{item.item.date_validity_format}}</label>
            <v-spacer></v-spacer>
            <strong
              style="padding: 0px 5px; border-style: solid; border-width: 1px; border-color: orange;"
            >
              <span v-if="item.item.date_validity_days">
                Em {{item.item.date_validity_days}} dia(s)
              </span>
              <span v-else>Hoje</span>
            </strong>
          </v-card-title>
          <v-card-title
            v-else-if="item.item.date_validity_format"
            style="padding: 5px;"
          >
            <v-icon>date_range</v-icon>
            <label><strong>Validade</strong>: {{item.item.date_validity_format}}</label>
            <v-spacer></v-spacer>
            <strong v-if="item.item.stage == 1" style="padding: 0px 5px; border-style: solid; border-width: 1px;">Em {{item.item.date_validity_days}} dia(s)</strong>
          </v-card-title>
        </div>
        <v-divider v-if="$Auth.user().account.id == '490fba83-2a5e-4b11-935a-754b25000880' && item.item.sum_quantity_unit"></v-divider>
        <v-card-title v-if="$Auth.user().account.id == '490fba83-2a5e-4b11-935a-754b25000880' && item.item.sum_quantity_unit" style="padding: 5px;">
          <v-icon>list</v-icon>
          <label><strong>Produtos/Serviços</strong>:</label>
          <v-spacer></v-spacer>
          <strong>{{item.item.sum_quantity_unit}}</strong>
        </v-card-title>
        <v-card-text style="padding: 0px;">
          <div v-if="item.item.count_attachment">
            <v-card-title style="padding: 5px;">
              <v-icon>attachment</v-icon>
              <span>{{item.item.count_attachment}}</span>
            </v-card-title>
            <v-divider></v-divider>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title style="padding: 5px; background: #0080009c; color: white;">
          <v-icon color="white">attach_money</v-icon>
          <label><strong>Valor:</strong></label>
          <v-spacer></v-spacer>
          <strong>{{ item.item.total_liquid | money }}</strong>
        </v-card-title>
      </div>
      <v-divider></v-divider>
      <v-card-text style="padding: 0px;">
        <v-card-actions style="padding: 0px 5px;">
          <v-btn
            icon flat
            @click="$WiEditDialog({
              wiConfig: config.store + '-open',
              onSubmit: item.refresh,
              data: item.item
            })"
          >
            <v-icon>edit</v-icon>
          </v-btn>
          <v-btn @click="$WiDialog(wiDialogs.events(item.item))" icon flat>
            <v-icon>timeline</v-icon>
          </v-btn>
          <v-btn @click="$WiDialog(wiDialogs.files(item.item))" icon flat>
            <v-icon>attachment</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-prms="{ 'id': 'f55197d7' }" icon flat
            @click="listTalkContactsByPerson(item.item)"
          >
            <v-icon color="#1e9e23">message</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </template>
</kanban>
</template>
<script>
  import kanban from '@/default/component/WiKanban/WiKanban'
  import talkViewMixin from '@/modules/talk/pages/TalkView/talkViewMixin'
  export default {
    name: 'BusinessOfProcess',
    data () {
      return {
        wiDialogs: {
          files: (business) => {
            return {
              title: 'Arquivos/Anexos',
              icon: 'attachment',
              component: {
                content: () => import('@/default/component/WiFile/WiFileList'),
                props: {
                  index: this.config.store + '-fileList',
                  api: {
                    url: this.config.module + '/' + this.config.store + '/file',
                    params: '?order=id,desc&where=business_id,' + business.id
                  }
                }
              },
              button: {
                icon: 'add',
                text: 'Adicionar Arquivo',
                action: () => {
                  this.$WiDialog({
                    title: 'Arquivos/Anexos ',
                    icon: 'attachment',
                    component: {
                      content: () => import('@/default/component/WiFile/WiFile'),
                      props: {
                        business_id: business.id
                      }
                    },
                    onClose: () => {
                      this.$WiView(this.config.store + '-fileList').refresh()
                    }
                  })
                }
              }
            }
          },
          events: (business) => {
            return {
              title: 'Anotações/Eventos ',
              icon: 'message',
              component: {
                content: () => import('@/default/component/WiTimeLine/WiTimeLine'),
                props: {
                  index: this.config.store + '-event',
                  api: {
                    url: this.config.module + '/' + this.config.store + '/event',
                    params: '?order=id,desc&where=business_id,' + business.id
                  }
                }
              },
              button: {
                icon: 'add',
                text: 'Adicionar Nota',
                action: () => {
                  this.$WiEditDialog({
                    wiConfig: this.config.store + '-event',
                    onSubmit: () => {
                      this.$WiView(this.config.store + '-event').refresh()
                    },
                    data: {business_id: business.id}
                  })
                }
              }
            }
          },
          finances: (business, refresh) => {
            return {
              title: 'Financeiro/Parcelas ',
              icon: 'message',
              component: {
                content: () => import('@/modules/sales/pages/Business/Finance/BusinessFinance'),
                props: {
                  config: this.config,
                  data: business,
                  refresh: refresh
                }
              }
            }
          }
        }
      }
    },
    methods: {
      goRouter: function (item) {
        var router = this.config.routerClick
        router.params['id'] = item.id
        this.$router.push(router)
      },
      listTalkContactsByPerson: function (business) {
        this.$WiListDialog({
          wiConfig: 'talk-contact-all',
          mode: 'select',
          callback: (contact) => {
            this.openTalkContact(contact)
          },
          filter: {
            person_id: business.person_id,
            order: 'talk_contacts.last_event_at,desc;talk_contacts.name,asc'
          }
        })
      }
    },
    computed: {
      config () {
        const business = this.$route.params.business
        switch (business) {
          case 'budget':
            return {
              type: 1,
              store: business,
              module: 'sales',
              storeStage: 'config-status-' + business,
              title: 'Processo de Orçamento',
              getUrl: 'sales/' + business + '/by-process',
              putUrl: 'sales/' + business,
              alertTitle: 'Deseja realmente alterar o status deste orçamento?',
              alertText: 'Essa ação só poderá ser desfeita manualmente!',
              routerClick: {name: business, params: {}}
            }
          case 'sale':
            return {
              type: 4,
              store: business,
              module: 'sales',
              storeStage: 'config-status-' + business,
              title: 'Processo de Vendas',
              getUrl: 'sales/' + business + '/by-process',
              putUrl: 'sales/' + business,
              alertTitle: 'Deseja realmente alterar o status desta venda?',
              alertText: 'Essa ação só poderá ser desfeita manualmente!',
              routerClick: {name: business, params: {}}
            }
          case 'purchase':
            return {
              type: 3,
              store: business,
              module: 'purchases',
              storeStage: 'config-status-' + business,
              title: 'Processo de Compra',
              getUrl: 'purchases/' + business + '/by-process',
              putUrl: 'purchases/' + business,
              alertTitle: 'Deseja realmente alterar o status desta compra?',
              alertText: 'Essa ação só poderá ser desfeita manualmente!',
              routerClick: {name: business, params: {}}
            }
          case 'quotation':
            return {
              type: 2,
              store: business,
              module: 'purchases',
              storeStage: 'config-status-' + business,
              title: 'Processo de Cotação Simples',
              getUrl: 'purchases/' + business + '/by-process',
              putUrl: 'purchases/' + business,
              alertTitle: 'Deseja realmente alterar o status desta cotação?',
              alertText: 'Essa ação só poderá ser desfeita manualmente!',
              routerClick: {name: business, params: {}}
            }
          case 'order':
            return {
              type: 5,
              store: business,
              module: 'service-desk',
              storeStage: 'config-status-' + business,
              title: 'Processo de Ordem de Serviço',
              getUrl: 'service-desk/' + business + '/by-process',
              putUrl: 'service-desk/' + business,
              alertTitle: 'Deseja realmente alterar o status desta Ordem de Serviço?',
              alertText: 'Essa ação só poderá ser desfeita manualmente!',
              routerClick: {name: business, params: {}}
            }
          case 'process':
            return {
              type: 7,
              store: business,
              module: 'juridical',
              storeStage: 'config-status-' + business,
              title: 'Etapas do Processo',
              getUrl: 'juridical/' + business + '/by-process',
              putUrl: 'juridical/' + business,
              alertTitle: 'Deseja realmente alterar o status deste Processo?',
              alertText: 'Essa ação só poderá ser desfeita manualmente!',
              date_end: 'Previsão',
              routerClick: {name: business, params: {}}
            }
          case 'project':
            return {
              type: 7,
              store: business,
              module: 'projects',
              storeStage: 'config-status-' + business,
              title: 'Etapas do Projeto',
              getUrl: 'projects/' + business + '/by-process',
              putUrl: 'projects/' + business,
              alertTitle: 'Deseja realmente alterar o status deste Projeto?',
              alertText: 'Essa ação só poderá ser desfeita manualmente!',
              date_end: 'Previsão',
              routerClick: {name: business, params: {}}
            }
          case 'graduation':
            return {
              type: 7,
              store: business,
              module: 'graduations',
              storeStage: 'config-status-' + business,
              title: 'Etapas da Formatura',
              getUrl: 'graduations/' + business + '/by-process',
              putUrl: 'graduations/' + business,
              alertTitle: 'Deseja realmente alterar o status desta Formatura?',
              alertText: 'Essa ação só poderá ser desfeita manualmente!',
              date_end: 'Previsão',
              routerClick: {name: business, params: {}}
            }
          case 'test-management-vtal':
            return {
              type: 30,
              store: business,
              module: 'service-desk',
              storeStage: 'config-status-' + business,
              title: 'Processo de Diagnóstico',
              getUrl: 'service-desk/' + business + '/by-process',
              putUrl: 'service-desk/' + business,
              alertTitle: 'Deseja realmente alterar o status deste Diagnóstico?',
              alertText: 'Essa ação só poderá ser desfeita manualmente!',
              routerClick: {name: business, params: {}}
            }
        }
        return {}
      }
    },
    components: {
      kanban
    },
    mixins: [talkViewMixin]
  }
</script>