import Talk from '@/modules/talk/pages/Talk/Talk'
import Talks from '@/modules/talk/pages/Talks/Talks'
import TalkFlow from '@/modules/talk/pages/TalkFlow'
import Dashboard from '@/modules/talk/pages/Dashboard'
import TalkView from '@/modules/talk/pages/TalkView/Talks/Talks'
import Campaigns from '@/modules/talk/pages/Campaigns'
import TalkChannels from '@/modules/talk/pages/TalkChannels'
import TalkViewMessages from '@/modules/talk/pages/TalkView/Talk/Talk'
import { PERMISSION_TALKS_DASHBOARD } from '@/default/constants/permissions'
import TalkChannelWhatsAppImportContacts from '@/modules/talk/pages/TalkChannel/TalkChannelWhatsAppImportContacts'
import TagsKanban from '@/modules/register/pages/Tags/TagsKanban'
import TalkMessageSelections from '@/modules/talk/pages/TalkMessages/TalkMessageSelections'
import TalkScheduleCalendar from '@/modules/talk/pages/TalkScheduleCalendar'

export default [
  {
    name: 'dashboard-talks',
    path: 'dashboard-talks',
    component: Dashboard,
    meta: {
      id: PERMISSION_TALKS_DASHBOARD,
      auth: true,
      title: 'Dashboard de Atendimentos',
      icon: 'dashboard'
    }
  },
  {
    name: 'message-selections',
    path: 'message-selections',
    component: TalkMessageSelections,
    meta: {
      id: PERMISSION_TALKS_DASHBOARD,
      auth: true,
      title: 'Seleções de Mensagens',
      icon: 'dashboard'
    }
  },
  {
    name: 'talks',
    path: 'talks',
    component: Talks,
    meta: {
      id: '123eab35-8984-4551-bd15-5b054b2ac8bf',
      auth: true,
      title: 'Conversas/Chats',
      icon: 'message'
    },
    children: [
      {
        name: 'talk-messages',
        path: 'messages/:id',
        component: Talk,
        meta: {
          id: '123eab35-8984-4551-bd15-5b054b2ac8bf',
          auth: true,
          title: 'Conversa/Chat',
          icon: 'message'
        }
      }
    ]
  },
  {
    name: 'talk-channel-whatsapp-import-contacts',
    path: 'talk-channel-whatsapp-import-contacts/:id',
    component: TalkChannelWhatsAppImportContacts,
    meta: {
      id: '123eab35-8984-4551-bd15-5b054b2ac8bf',
      auth: true,
      title: 'Canal de Atendimento, importar contatos',
      icon: 'message'
    }
  },
  {
    name: 'talk',
    path: 'talk/:id',
    component: Talk,
    meta: {
      id: '123eab35-8984-4551-bd15-5b054b2ac8bf',
      auth: true,
      title: 'Conversa/Chat',
      icon: 'message'
    }
  },
  {
    name: 'talk-view',
    path: 'talk-view',
    component: TalkView,
    meta: {
      id: '123eab35-8984-4551-bd15-5b054b2ac8bf',
      auth: true,
      title: 'Conversa/Chat',
      icon: 'message',
      touch: true
    },
    children: [
      {
        name: 'talk-view-messages',
        path: 'messages/:id',
        component: TalkViewMessages,
        meta: {
          id: '123eab35-8984-4551-bd15-5b054b2ac8bf',
          auth: true,
          title: 'Conversa/Chat',
          icon: 'message',
          touch: true
        }
      }
    ]
  },
  {
    name: 'talk-view-messages-page',
    path: 'messages-page/:id',
    component: TalkViewMessages,
    meta: {
      id: '123eab35-8984-4551-bd15-5b054b2ac8bf',
      auth: true,
      title: 'Conversa/Chat',
      icon: 'message',
      touch: true
    }
  },
  {
    name: 'talk-flow',
    path: 'talk-flow',
    component: TalkFlow,
    meta: {
      id: '23af840f',
      auth: true,
      title: 'Fluxos de Atendimento',
      icon: 'message',
      touch: true
    }
  },
  {
    name: 'talks-tags-kanban',
    path: 'talks-tags-kanban',
    component: TagsKanban,
    meta: {
      id: '9f88b04d',
      auth: true,
      title: 'Tags Kanban',
      icon: 'view_week',
      touch: true
    }
  },
  {
    name: 'talks-campaigns',
    path: 'talks-campaigns',
    component: Campaigns,
    meta: {
      id: '123eab35-8984-4551-bd15-5b054b2ac8bf',
      auth: true,
      title: 'Campanhas e envio em massa',
      icon: 'send_to_mobile',
      touch: true
    }
  },
  {
    name: 'talk-channels',
    path: 'talk-channels',
    component: TalkChannels,
    meta: {
      id: 'b49f9043',
      auth: true,
      title: 'Canais de atendimento',
      icon: 'play_for_work',
      touch: true
    }
  },
  {
    name: 'talk-schedules-calendar',
    path: 'talk-schedules-calendar',
    component: TalkScheduleCalendar,
    meta: {
      id: '123eab35-8984-4551-bd15-5b054b2ac8bf',
      auth: true,
      title: 'Calendário de Agendamentos',
      icon: 'event',
      touch: true
    }
  }
]
