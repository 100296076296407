import Stock from '@/modules/stock/config/Stock/Stock'
import StockProduct from '@/modules/stock/config/Stock/StockProduct'
import StockMovement from '@/modules/stock/config/Stock/StockMovement'
import StockTransfer from '@/modules/stock/config/Stock/StockTransfer'

import { TransferIn } from '@/modules/stock/config/Transfer/TransferIn'
import { TransferInCollaborator } from '@/modules/stock/config/Transfer/TransferInCollaborator'
import { TransferInCustomer } from '@/modules/stock/config/Transfer/TransferInCustomer'
import { TransferOut } from '@/modules/stock/config/Transfer/TransferOut'
import { TransferOutCollaborator } from '@/modules/stock/config/Transfer/TransferOutCollaborator'
import { TransferOutCustomer } from '@/modules/stock/config/Transfer/TransferOutCustomer'

import { MovementEntry } from '@/modules/stock/config/Movement/MovementEntry'
import { MovementRemoval } from '@/modules/stock/config/Movement/MovementRemoval'
import { MovementEntryCollaborator } from '@/modules/stock/config/Movement/MovementEntryCollaborator'
import { MovementRemovalCollaborator } from '@/modules/stock/config/Movement/MovementRemovalCollaborator'
import { MovementEntryCustomer } from '@/modules/stock/config/Movement/MovementEntryCustomer'
import { MovementRemovalCustomer } from '@/modules/stock/config/Movement/MovementRemovalCustomer'

import StockRegisterProduct from '@/modules/stock/config/Register/Product'

import Category from '@/modules/stock/config/Config/Category'

import status from '@/modules/stock/config/Config/Status'

import Event from '@/modules/stock/config/Common/Event'

import {
  ProductEntry, ProductEntryCustomer, ProductEntryCollaborator, ProductRemoval, ProductRemovalCustomer, ProductRemovalCollaborator
} from '@/modules/stock/config/Common/ProductEntry'
import {
  ProductTransferIn, ProductTransferInCollaborator, ProductTransferInCustomer, ProductTransferOut, ProductTransferOutCollaborator, ProductTransferOutCustomer
} from '@/modules/stock/config/Common/ProductEntry'

export default {
  'stock': Stock.stock,
  'stock-physical': Stock.physical,
  'stock-virtual': Stock.virtual,
  'stock-collaborator': Stock.collaborator,
  'stock-customer': Stock.customer,

  'stock-product': StockProduct,
  'stock-movement': StockMovement,
  'stock-transfer': StockTransfer,
  'stock-register-product': StockRegisterProduct,



  'product-entry': new MovementEntry(),
  'product-entry-open': new MovementEntry(),
  'product-removal': new MovementRemoval(),
  'product-removal-open': new MovementRemoval(),
  'product-entry-collaborator': new MovementEntryCollaborator(),
  'product-entry-collaborator-open': new MovementEntryCollaborator(),
  'product-removal-collaborator': new MovementRemovalCollaborator(),
  'product-removal-collaborator-open': new MovementRemovalCollaborator(),
  'product-entry-customer': new MovementEntryCustomer(),
  'product-entry-customer-open': new MovementEntryCustomer(),
  'product-removal-customer': new MovementRemovalCustomer(),
  'product-removal-customer-open': new MovementRemovalCustomer(),

  'product-entry-item-product': new ProductEntry(),
  'product-entry-collaborator-item-product': new ProductEntryCollaborator(),
  'product-entry-customer-item-product': new ProductEntryCustomer(),
  'product-removal-item-product': new ProductRemoval(),
  'product-removal-collaborator-item-product': new ProductRemovalCollaborator(),
  'product-removal-customer-item-product': new ProductRemovalCustomer(),

  'product-entry-event': Event.productEntry,
  'product-entry-collaborator-event': Event.productEntryCollaborator,
  'product-entry-customer-event': Event.productEntryCustomer,
  'product-removal-event': Event.productRemoval,
  'product-removal-collaborator-event': Event.productRemovalCollaborator,
  'product-removal-customer-event': Event.productRemovalCustomer,

  'category-product-entry': Category.productEntry,
  'category-product-entry-collaborator': Category.productEntryCollaborator,
  'category-product-entry-customer': Category.productEntryCustomer,
  'category-product-removal': Category.productRemoval,
  'category-product-removal-collaborator': Category.productRemovalCollaborator,
  'category-product-removal-customer': Category.productRemovalCustomer,

  'status-product-entry': status.productEntry,
  'status-product-entry-collaborator': status.productEntryCollaborator,
  'status-product-entry-customer': status.productEntryCustomer,
  'status-product-removal': status.productRemoval,
  'status-product-removal-collaborator': status.productRemovalCollaborator,
  'status-product-removal-customer': status.productRemovalCustomer,



  'product-transfer-in': new TransferIn(),
  'product-transfer-in-open': new TransferIn(),
  'product-transfer-in-collaborator': new TransferInCollaborator(),
  'product-transfer-in-collaborator-open': new TransferInCollaborator(),
  'product-transfer-in-customer': new TransferInCustomer(),
  'product-transfer-in-customer-open': new TransferInCustomer(),
  'product-transfer-out': new TransferOut(),
  'product-transfer-out-open': new TransferOut(),
  'product-transfer-out-collaborator': new TransferOutCollaborator(),
  'product-transfer-out-collaborator-open': new TransferOutCollaborator(),
  'product-transfer-out-customer': new TransferOutCustomer(),
  'product-transfer-out-customer-open': new TransferOutCustomer(),
  
  'product-transfer-in-item-product': new ProductTransferIn(),
  'product-transfer-in-collaborator-item-product': new ProductTransferInCollaborator(),
  'product-transfer-in-customer-item-product': new ProductTransferInCustomer(),
  'product-transfer-out-item-product': new ProductTransferOut(),
  'product-transfer-out-collaborator-item-product': new ProductTransferOutCollaborator(),
  'product-transfer-out-customer-item-product': new ProductTransferOutCustomer(),

  'product-transfer-in-event': Event.productTransferIn,
  'product-transfer-in-collaborator-event': Event.productTransferInCollaborator,
  'product-transfer-in-customer-event': Event.productTransferInCustomer,
  'product-transfer-out-event': Event.productTransferOut,
  'product-transfer-out-collaborator-event': Event.productTransferOutCollaborator,
  'product-transfer-out-customer-event': Event.productTransferOutCustomer,

  'category-product-transfer-in': Category.productTransferIn,
  'category-product-transfer-in-collaborator': Category.productTransferInCollaborator,
  'category-product-transfer-in-customer': Category.productTransferInCustomer,
  'category-product-transfer-out': Category.productTransferOut,
  'category-product-transfer-out-collaborator': Category.productTransferOutCollaborator,
  'category-product-transfer-out-customer': Category.productTransferOutCustomer,

  'status-product-transfer-in': status.productTransferIn,
  'status-product-transfer-in-collaborator': status.productTransferInCollaborator,
  'status-product-transfer-in-customer': status.productTransferInCustomer,
  'status-product-transfer-out': status.productTransferOut,
  'status-product-transfer-out-collaborator': status.productTransferOutCollaborator,
  'status-product-transfer-out-customer': status.productTransferOutCustomer
}
