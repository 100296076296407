<template>
  <v-card class="elevation-5" :color="color">
    <v-card-text style="padding: 5px 0 0 0; text-align: center;">
      <h2 style="color: white;">
        <v-icon large color="white">
          {{ icon }}
        </v-icon>
        <br/>
        <span style="font-size: 32px;">
          {{ data }}
        </span>
      </h2>
      <v-divider></v-divider>
      <v-btn color="white" small flat block :disabled="!route" link :to="route">
        {{ title }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'DashboardTalksCard',
  props: {
    title: {
      required: true,
      type: String
    },
    icon: {
      required: true,
      type: String
    },
    color: {
      required: false,
      default: 'green'
    },
    data: {
      required: false,
      default: '- - -'
    },
    route: {
      required: false,
      type: Object
    }
  }
}
</script>