<template>
  <div>
    <v-btn @click="testWebhook()">Send New Event</v-btn>
    <v-btn @click="systemNotifications()">Send New Notification</v-btn>
    <v-btn @click="loadApi()">Send New Request</v-btn>
    <v-btn @click="savePanels()">Save Panels</v-btn>
    <v-btn @click="checkPanelsId()">Check Panels Id</v-btn>
    <v-btn @click="convert()">convert</v-btn>
    <h1></h1>
  </div>
</template>
<script>
  import {callApi} from '@/default/service/Api'
  import axios from 'axios'
  import convert from './convert'
  import uispDevices from './uispDevices.json'
  export default {
    name: 'Teste',
    data () {
      return {
        count: 999,
        socket: null,
        content: '<h1>Some initial content</h1>',
        customToolbar: [
        [{ 'header': [false, 1, 2, 3, 4, 5, 6] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{align: ''}, {align: 'center'}, {align: 'right'}, {align: 'justify'}],
        [{ 'color': [] }, { 'background': [] }],
        [{list: 'ordered'}, {list: 'bullet'}, {list: 'check'}]
        ]
      }
    },
    methods: {
      convert: function () {
        console.log(convert.toLat(-20.2877772, 'dms') + ' - ' + convert.toLon(-45.5396929, 'dms'))
        console.log(convert.parse('020° 17′ 16″ S') + ' - ' + convert.parse('120° 32′ 23″ W'))
        console.log(`__°__'__"_`);
      },
      onSubmit: function () {
        console.log('Hello World!')
      },
      loadApi: function () {
        axios.get(
          'https://wnewer-public-test-1.s3.sa-east-1.amazonaws.com/agora/posts/test-materia-123.txt?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEMj%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXNhLWVhc3QtMSJGMEQCIBGDQ2ZhnZCHoHAK2tDLKjem0TG9d6TdWqnisvcNbsUxAiAjvZ9bv99iobvyKbEKsjnUMm0uuHCxxvpsGjc3VQCyPCrtAgjh%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAAaDDY2OTcxNDcwMDAxMCIMimFyJf%2FkxQHsZMsNKsECnlTlLRCyTWFByZo65HglObk%2FzeodK7Xn3r9LLJ4CURt8z%2F67gUalpVYHWt%2Fvlqv%2BDQsXSGBBP6j4F2eg7a4T%2FwUAbd2mM1WQINmg%2B7KMWH7WjlxSY4SlzRjIzqgAEAbZOttkRKFR55M5ZXxxoZp0AGSJhPvYk7VF0T0B6KMwf2n%2FnZhCJf5fTX1Szh1VG21NA%2FW0fPa2qEBRc8v8k14yh7N1KydL1DPOOC2gj%2FX9nMIucm92Jh%2BdSsFKXiNOQfOg7ZiBegxgTQZOUf0oD41c8q4hNgb4sCx9a%2FWLONlIbDlJ3Z6CGxs0pcwq0EXF1AJ9zQ72YWtweWMjWpq%2BZ73I1622VdnFHmCu8vu5aHgwuWqVrWqxqCrjp1bazz0J6JVQdVnjQ%2F%2Fog4eV6tEqw1B5A1sWteUigrds98tzWPNDFY1UMOOcnY8GOrQCCYM5ggc8dD8ZAuojHqrMVvGVncqWR9iiZRcQ1MovUINbdtizj6%2FJY%2BtZ1P1zRwKNZn9QKdT%2BQnP20lk1yMsTLmj3l9Gb7pBmGpiG0Tbv3AssT1mfJ4fojNOW%2FDMZZD%2BoSCfyqqyX9OtRL2YyAHBzShzCw9b70rPMQqTOU22Rh%2F%2FFSC%2F2AhZDPVCyIQwoggKJRUpHsVluBr5aJjVnazOgAquEbZLlletNcRV1vhcxga7auhZ28sJRZnQBC1GMFWFEIR9vr2wAJ3h%2FHcTYOEv40Js62g70LPF3aB7S%2Bun%2FGW%2Bb4IxdDOEpztuam6gpdTjjvPVtzVABEs5f0qECYufLDVF%2FPacomDAloj%2B8kUtMiF%2FDD3FgmnGa3HMxz7rVaKiAeJ9p7ZP8gW0ABddBsmhcrZePZdE%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220119T004710Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIAZX3Q3K3VL5QIYGK4%2F20220119%2Fsa-east-1%2Fs3%2Faws4_request&X-Amz-Signature=b115e7716efa3f03d45941b7b217219fda7e441e7cb7ee441bcea3fc22c3ed35'
        )
      },
      test: function () {
        callApi.get({uri: 'send', params: {message: 'Hello World!'}})
      },
      systemNotifications: function () {
        var notification = new Notification('Hello World!', {
          body: 'Hello World!',
          icon: 'https://master.view.wises.tech/static/systems/connect_view.png'
        })
        notification.onclick = function () {
          window.open(window.location.href)
        }
        // if (!('Notification' in window)) {
        //   alert('Este browser não suporta notificações de Desktop')
        // } else if (Notification.permission === 'granted') {
        //   console.log('Notificações ativadas.')
        // } else if (Notification.permission !== 'denied') {
        //   Notification.requestPermission(function (permission) {
        //     if (permission !== 'granted') {
        //       alert('É altamente recomendado que você ative as notificações para receber alertas do sistema.')
        //     }
        //   })
        // }
      },
      testWebhook: function () {
        let count = 1
        const data = {
          'index': count,
          'type': 'message',
          'user': {
            'id': '553791641057@c.us',
            'name': 'Higor Rezende',
            'image': 'https://static1.purepeople.com.br/articles/0/14/30/60/@/1777090-grazi-massafera-nao-planeja-seguir-carre-624x600-2.jpg',
            'phone': '553791641057'
          },
          'reply': 'https://api.maytapi.com/api/32480bdf-0c64-495a-8f50-a0c84600f992/3717/sendMessage',
          'message': {
            'id': 'false_553592397977-1606501802@g.us_DD89487D6CE786E8C111A419C0FFBCF2',
            'text': '*' + this.count++ + '* - ' + count,
            'type': 'text',
            'fromMe': false,
            '_serialized': 'false_553592397977-1606501802@g.us_DD89487D6CE786E8C111A419C0FFBCF2'
          },
          'phoneId': 3717,
          'phone_id': 3717,
          'receiver': '553735124750',
          'productId': '32480bdf-0c64-495a-8f50-a0c84600f992',
          'timestamp': parseInt(Date.now().toString().substr(0,10)),
          'product_id': '32480bdf-0c64-495a-8f50-a0c84600f992',
          'conversation': '553791641057@c.us'
        }
        // data.message.text = '*' + this.count + '* - ' + count++
        // console.log(data.message.text)
        // axios.post(
        //   'https://account9.develop.server:8843/api/integration/maytapi/255965c9-76f6-428a-b98c-c859f641e08c',
        //   data
        // )
        const interval = setInterval(() => {
          data.index = count
          data.message.text = '*' + this.count + '* - ' + count++
          console.log(data.message.text)
          let token = 888
          if (count % 2 === 0) {
            token = '08c'
          }
          axios.post(
            'https://account9.develop.server:8843/api/integration/maytapi/255965c9-76f6-428a-b98c-c859f641e' + token,
            data
          )
          if (count > 3) {
            clearInterval(interval)
          }
        }, 0);
        
        // data.index = count
        // data.message.text = '*' + this.count + '* - ' + count++
        // console.log(data.message.text)
        // axios.post(
        //   'https://account9.develop.server:8843/api/integration/maytapi/255965c9-76f6-428a-b98c-c859f641e08c',
        //   data
        // )
        // data.index = count
        // data.message.text = '*' + this.count + '* - ' + count++
        // console.log(data.message.text)
        // axios.post(
        //   'https://account9.develop.server:8843/api/integration/maytapi/255965c9-76f6-428a-b98c-c859f641e08c',
        //   data
        // )
        // data.index = count
        // data.message.text = '*' + this.count + '* - ' + count++
        // console.log(data.message.text)
        // axios.post(
        //   'https://account9.develop.server:8843/api/integration/maytapi/255965c9-76f6-428a-b98c-c859f641e08c',
        //   data
        // )
      },
      savePanels: function () {
        callApi.get({
          uri: 'network/device', 
          params: {
            // where: 'ativo_disp,1',
            limit: 10000,
            fields: 'id,uuid,nome_disp,ip_disp,ativo_disp',
            order: 'id,asc'
          }, 
          sucess: response => {
            // console.log('cview devices', response)
            this.prcessPanels(response.data.data)
          }
        })
        
      },
      prcessPanels: function (panels) {
        const panelsProcessed = []
        console.log('panels', panels);

        // {
        //   "id": "0687920e-ee6e-407d-9e6f-cd52231dc435",
        //   "ipAddress": "191.53.192.13",
        //   "mac": "24:a4:3c:ac:fd:56",
        //   "name": "MASTER-DIV-5.8-26",
        //   "hostname": "191.53.192.13/20",
        //   "model": "R5N",
        //   "modelName": "Rocket M5"
        // }

        // console.log('devicesNMS', devicesNMS)
          console.log(devicesNMS.length)
        const devicesNotFoundInUISP = []
        let devicesNMSFormated = devicesNMS.filter(panel => (panel.ipAddress))

        // try {
        //   devicesNMSFormated = devicesNMS
        //   .filter(panel => (panel.ipAddress))
        //   .map(panel => ({
        //     id: panel.identification?.id,
        //     name: panel.identification?.name,
        //     mac: panel.identification?.mac,
        //     ipAddress: panel.ipAddress?.split('/')[0],
        //     model: panel.identification?.model,
        //     modelName: panel.identification?.modelName,
        //     latitude: panel.location?.latitude,
        //     longitude: panel.location?.longitude
        //   }))
        // }
        // catch (e) {
        //   console.log('error', e.message)
        // }


        console.log('devicesNMSFormated', devicesNMSFormated)
        
        devicesNMSFormated.forEach(deviceNMS => {
          const device = this.getDevice(panels, deviceNMS.ipAddress)
          if (device) {
            const deviceCreated = {
              id: device.id,
              type_id: 5,
              technology_id: 4,
              producer_id: 8457,
              uuid: deviceNMS.id,
              mac_disp: deviceNMS.mac,
              latitude_disp: deviceNMS.latitude,
              longitude_disp: deviceNMS.longitude,
            }
            if (deviceNMS.mac) {
              deviceCreated.mac_disp = deviceNMS.mac
            }
            panelsProcessed.push(deviceCreated)
          } else {
            console.log('Device not found', deviceNMS.ipAddress)
            devicesNotFoundInUISP.push(deviceNMS)
          }
        });
        console.log('devicesNotFoundInConnect: ' + devicesNotFoundInUISP?.length + '. \n', JSON.stringify(devicesNotFoundInUISP))
        console.log(panelsProcessed)
        this.sendPanels(panelsProcessed, 0, 100)

        const devicesNotFoundInUISP1 = []
        panels.forEach(panel => {
          const device = this.getDeviceInUISP(devicesNMSFormated, panel.ip_disp)
          if (!device) {
            console.log('Device not found in NMS', panel.ip_disp)
            devicesNotFoundInUISP1.push({
              id: panel.id,
              ipAddress: panel.ip_disp,
              name: panel.nome_disp
            })
          }
        });

        console.log('devicesNotFoundInNMS: ' + devicesNotFoundInUISP1?.length + '. \n', JSON.stringify(devicesNotFoundInUISP1))
        // const devices1 = panelsProcessed.slice(0, 500)
        // this.updateDevices(devices1, 0)
        // const devices2 = panelsProcessed.slice(500, 1000)
        // this.updateDevices(devices2, 58000)
        // const devices3 = panelsProcessed.slice(1000, 1500)
        // this.updateDevices(devices3, 118000)
        // const devices4 = panelsProcessed.slice(1500, 2000)
        // this.updateDevices(devices4, 177000)
        // const devices5 = panelsProcessed.slice(2000, 2500)
        // this.updateDevices(devices5, 235000)
        // const devices7 = panelsProcessed.slice(2500, 3000)
        // this.updateDevices(devices7, 290000)
      },
      sendPanels: async function (devices, firstDevice, lastDevice) {
          const size = 100
          const devicesSelected = devices.slice(firstDevice, lastDevice)
          console.log('send', devicesSelected.length, 'of', devices.length, 'first', devicesSelected[0]?.id, 'last', devicesSelected?.slice(-1)[0]?.id);
          await callApi.put({
            uri: 'network/device',
            id: 'multiple',
            data: devicesSelected, 
            sucess: response => {
              console.log(response)
            }
          })
          if (devicesSelected.length > 0) {
            this.sendPanels(devices, firstDevice + size, lastDevice + size)
          } else {
            alert('Hello World!')
          }
          
      },
      updateDevices: function (devices, timeout) {
        console.log(devices);
        setTimeout(() => {
          callApi.put({
            uri: 'network/device',
            id: 'multiple',
            data: devices, 
            sucess: response => {
              console.log(response)
            }
          })
        }, timeout);
      },
      getDevice: function (devices, ipAddress) {
        let deviceSelected = null
        devices.forEach(device => {
          if (device.ip_disp == ipAddress) {
            console.log(device.ip_disp + '|' + ipAddress);
            deviceSelected = device
          }
        });
        return deviceSelected
      },
      getDeviceInUISP: function (devices, ip_disp) {
        let deviceSelected = null
        devices.forEach(device => {
          if (device.ipAddress == ip_disp) {
            console.log(device.ipAddress + '|' + ip_disp);
            deviceSelected = device
          }
        });
        return deviceSelected
      },
      checkPanelsId: function (devicessNMS) {
        const ids = []
        const idsDuplicated = []
        devicessNMS.forEach(deviceNMS => {
          if (ids.indexOf(deviceNMS.uuid) > -1) {
            idsDuplicated.push(deviceNMS.uuid)
          }
          ids.push(deviceNMS.uuid)
        });
        console.log('idsDuplicated', idsDuplicated);
      }
    },
    mounted () {
      // this.socket = window.io('http://localhost:3000')
      // this.socket.on('getServersOnline', function (data) {
      //   console.log('getServersOnline')
      //   console.log(data)
      // })
      // eslint-disable-next-line
      // window.Echo.private('network.1')
      // .listen('.status.changed', (receive) => {
      //   console.log(receive)
      // })
      // this.socket = new Socket()
      // this.socket.setPort(8750)
      // this.socket.setOnConnect(function () {
      //   console.log('Connect!')
      // })
      // this.socket.setOnDisconnect(function () {
      //   console.log('Disconnect!')
      // })
      // this.socket.connect()
      // this.socket.private('network.1')
      // .listen('status.changed', (receive) => {
      //   console.log(receive)
      // })
    },
    beforeDestroy () {
      // this.socket.disconnect()
      console.log('beforeDestroy')
    },
    computed: {
      user () {
        return this.$store.state.user
      }
    }
  }
  const devicesNMS = uispDevices
  
</script>